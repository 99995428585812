import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://66917926a32665ba0b5e408d4bb7f19d@o4506334305714176.ingest.us.sentry.io/4508763012399104",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()